<template>
  <div
    id="masonryContainer"
    ref="masonryContainer"
    class="row q-col-gutter-lg"
    transition-duration="0.1s"
    item-selector=".item"
  >
    <slot :wrapClass="wrapClass" />
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'
// Masonry imported in noSsr boot file

export default {
  props: {
    reactive: {
      type: Object
    }
  },
  data () {
    return {
      msnry: null,
      debouncedSetupMasonry: null,
      wrapClass: 'item col-12 col-sm-6 col-md-4 col-lg-3',
    }
  },
  watch: {
    reactive: {
      deep: true,
      immediate: true,
      handler: async function (val) {
        if (val) this.$nextTick(() => { this.setupMasonry() })
      }
    },
  },
  mounted () {
    this.debouncedSetupMasonry = this.$_.debounce(this.setupMasonry, 200)
  },
  methods: {
    // Debounced to prevent multiple rapid executions
    setupMasonry () {
      if (process.env.CLIENT && typeof window !== 'undefined') {
        const displayWidth = document.getElementById('masonryContainer').clientWidth
        this.wrapClass = this.$_.get([
          { from: 0, to: 599, class: 'item col-12' },
          { from: 600, to: 1023, class: 'item col-12 col-sm-6' },
          { from: 1024, to: 1439, class: 'item col-12 col-sm-6 col-md-4' },
          { from: 1440, to: 999999, class: 'item col-12 col-sm-6 col-md-4 col-lg-3' },
        ].find(m => m.to > displayWidth && displayWidth >= m.from), 'class', 'item col-12 col-sm-6 col-md-4 col-lg-3')
        this.$nextTick(() => {
          imagesLoaded(document.querySelector('#masonryContainer'), (instance) => {
            if (!this.msnry) this.msnry = new this.$Masonry('#masonryContainer', { itemSelector: '.item' })
            this.msnry.layout()
          })
        })
      }
    },
  }
}
</script>
