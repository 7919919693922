<template>
  <div>
    <QInput
      v-model="inputModel"
      outlined
      :readonly="props.readonly"
      :label="computedLabel(props.label)"
      @keydown.enter="toggleValue(inputModel)"
    >
      <template #append>
        <QIcon
          name="uil:plus"
          class="cursor-pointer"
          @click="toggleValue(inputModel)"
        />
      </template>
    </QInput>
    <div class="full-width">
      <HcChip
        v-for="(t, i) of cptValue"
        :key="i"
        :label="t"
        color="secondary"
        removable
        clickable
        @remove="toggleValue(t)"
      />
    </div>
  </div>
</template>

<script setup>
import { xor } from 'lodash'
import { computed, ref } from 'vue'
import { computedLabel } from 'hc-core/composables/intl'

const props = defineProps({
  modelValue: String,
  readonly: Boolean,
  label: String,

  // Allow custom get/set, if transformation needed (handle Array or String)
  getFn: Function,
  setFn: Function
})
const emit = defineEmits(['update:modelValue'])
const cptValue = computed({
  get: () => props.getFn ? props.getFn(props.modelValue) : props.modelValue,
  set: (value) => {
    if (props.setFn) value = props.setFn(value)
    emit('update:modelValue', value)
  }
})

const inputModel = ref('')

function toggleValue (value) {
  cptValue.value = xor(cptValue.value, [value])
  inputModel.value = ''
}
</script>
