<template>
  <QNoSsr v-if="graph">
    <VueApexCharts
      v-if="onClient && !loading"
      :options="graph"
      :series="graph.series"
      height="100%"
    />
    <HCLoading v-else />
  </QNoSsr>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const AsyncVueApexCharts = process.env.CLIENT ? defineAsyncComponent(() => import('vue3-apexcharts')) : null

export default {
  components: { VueApexCharts: AsyncVueApexCharts },
  props: {
    graph: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    onClient () { return process.env.CLIENT },
  }
}
</script>
